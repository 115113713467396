import request from '@/utils/request'

//         设备控制器

// 设备 分页
export function devicePage(query) {
  return request({
    url: '/wisdom/wisdom-device/page',
    method: 'get',
    params: query
  })
}

// 设备 详情
export function deviceInfo(query) {
  return request({
    url: '/wisdom/wisdom-device/detail',
    method: 'get',
    params: query
  })
}

// 设备 新增
export function deviceAdd(data) {
  return request({
    url: '/wisdom/wisdom-device/add',
    method: 'post',
    data
  })
}

// 设备 编辑
export function deviceEdit(data) {
  return request({
    url: '/wisdom/wisdom-device/edit',
    method: 'post',
    data
  })
}

// 设备 删除
export function deviceDel(data) {
  return request({
    url: '/wisdom/wisdom-device/delete',
    method: 'post',
    data
  })
}

// 设备 导入
export function importData(data) {
  return request({
    url: '/wisdom/wisdom-device/import/data/info',
    method: 'post',
    data
  })
}

// 设备 导出
export function deviceExport(data) {
  return request({
    url: '/wisdom/wisdom-device/export',
    method: 'post',
    data
  })
}

// 设备 批量开关阀控制
export function deviceEditValve(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/valve',
    method: 'post',
    data
  })
}

// 设备 批量设置用量警告
export function deviceEditWarn(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/warn',
    method: 'post',
    data
  })
}

// 设备 批量设置配价方案
export function deviceOrdinateSz(data) {
  return request({
    url: '/wisdom/wisdom-device/ordinate/sz',
    method: 'post',
    data
  })
}

// 设备 批量设置结算日
export function deviceEditJsday(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/jsday',
    method: 'post',
    data
  })
}

// 设备 批量设置上报周期
export function deviceEditZq(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/zq',
    method: 'post',
    data
  })
}

// 设备 批量设置所属公司
export function deviceEditSsgs(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/ssgs',
    method: 'post',
    data
  })
}

// 设备 设置表底数
export function deviceEditBds(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/bds',
    method: 'post',
    data
  })
}

// 设备 更换表号
export function deviceEditGhbh(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/ghbh',
    method: 'post',
    data
  })
}

// 设备 一键换表
export function deviceEditHbhao(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/hbhao',
    method: 'post',
    data
  })
}

// 设备 批量设置表端预付
export function deviceEditYf(data) {
  return request({
    url: '/wisdom/wisdom-device/edit/yf',
    method: 'post',
    data
  })
}

//              设备类型控制器

// 设备类型 列表
export function deviceTypeList(query) {
  return request({
    url: '/wisdom/wisdom-device-type/list',
    method: 'get',
    params: query
  })
}

// 设备类型 分页
export function deviceTypePage(query) {
  return request({
    url: '/wisdom/wisdom-device-type/page',
    method: 'get',
    params: query
  })
}

// 设备类型 详情
export function deviceTypeInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-type/detail',
    method: 'get',
    params: query
  })
}

// 设备类型 新增
export function deviceTypeAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-type/add',
    method: 'post',
    data
  })
}

// 设备类型 编辑
export function deviceTypeEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-type/edit',
    method: 'post',
    data
  })
}

// 设备类型 删除
export function deviceTypeDel(data) {
  return request({
    url: '/wisdom/wisdom-device-type/delete',
    method: 'post',
    data
  })
}

//              设备配价控制器

// 设备配价 列表
export function deviceOrdinateList(query) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/list',
    method: 'get',
    params: query
  })
}

// 设备配价 分页
export function deviceOrdinatePage(query) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/page',
    method: 'get',
    params: query
  })
}

// 设备配价 详情
export function deviceOrdinateInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/detail',
    method: 'get',
    params: query
  })
}

// 设备配价 新增
export function deviceOrdinateAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/add',
    method: 'post',
    data
  })
}

// 设备配价 编辑
export function deviceOrdinateEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/edit',
    method: 'post',
    data
  })
}

// 设备配价 删除
export function deviceOrdinateDel(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate/delete',
    method: 'post',
    data
  })
}

//              设备阶梯类型控制器

// 阶梯类型 分页
export function deviceOrdinateTypePage(query) {
  return request({
    url: '/wisdom/wisdom-device-ordinate-type/page',
    method: 'get',
    params: query
  })
}

// 阶梯类型 详情
export function deviceOrdinateTypeInfo(query) {
  return request({
    url: '/wisdom/wisdom-device-ordinate-type/detail',
    method: 'get',
    params: query
  })
}

// 阶梯类型 新增
export function deviceOrdinateTypeAdd(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate-type/add',
    method: 'post',
    data
  })
}

// 阶梯类型 编辑
export function deviceOrdinateTypeEdit(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate-type/edit',
    method: 'post',
    data
  })
}

// 阶梯类型 删除
export function deviceOrdinateTypeDel(data) {
  return request({
    url: '/wisdom/wisdom-device-ordinate-type/delete',
    method: 'post',
    data
  })
}

//              设备故障记录控制器

// 故障记录 分页
export function deviceFaultPage(query) {
  return request({
    url: '/wisdom/wisdom-device-fault/page',
    method: 'get',
    params: query
  })
}

// 故障记录 处理
export function deviceFaultStatus(data) {
  return request({
    url: '/wisdom/wisdom-device-fault/status',
    method: 'post',
    data
  })
}

// 故障记录 删除
export function deviceFaultDelete(data) {
  return request({
    url: '/wisdom/wisdom-device-fault/delete',
    method: 'post',
    data
  })
}

//              换表记录控制器

// 换表记录 分页
export function changeWatchPage(query) {
  return request({
    url: '/wisdom/wisdom-dev-change-watch/page',
    method: 'get',
    params: query
  })
}

//              查询设备设置指令记录分页

// 查询设备设置指令记录分页 分页
export function deviceSetInstructPage(query) {
  return request({
    url: '/wisdom/wisdom-device-set-instruct/page',
    method: 'get',
    params: query
  })
}
